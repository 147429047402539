<template>
  <div class="page-wrapper">
    <PageHeader title="Wallet" subTitle="Summary" />

    <Table dataType="walletSummary" url="wallet/summary" title="Cash Wallet" />
  </div>
</template>
<script>
import Table from "@/components/Table";
import PageHeader from "@/components/PageHeader.vue";

export default {
  components: { Table, PageHeader },
};
</script>
