import { createApp } from 'vue';
import App from './App.vue';
// import router from './router';
import store from '../store';
import axios from 'axios';
// import VueCookies from 'vue3-cookies';
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3';

require('@/store/subscriber');

axios.defaults.withCredentials = true;

createApp(App)
  .use(store)
  .use(VueReCaptcha, { siteKey: '6Ld7YLMaAAAAAEhwTcITb3v5lbcddU_FSmvemmM6'
  //uncomment to hide the badge, loaderOptions:{autoHideBadge:true}
} )
  // .use(VueCookies)
  // .use(router)
  .mount('#app');
