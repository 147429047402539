<template>
  <div class="page-wrapper">
	    <PageHeader title="Profile" subTitle="Edit Profile"/>
			<div class="page-body">
				<div class="container-xl">
					<div class="row row-cards">
						<div class="col-md-6 my-3">
							<div class="card">
								<div class="card-header">
									<h3 class="card-title">Personal Information</h3>
								</div>
								<div class="card-body">
									<dl class="row">
										<dt class="col-5">Member ID:</dt>
										<dd class="col-7">{{ user.memberID }}</dd>
										<dt class="col-5">User Name:</dt>
										<dd class="col-7">{{ user.userName }}</dd>
										<dt class="col-5">Sponsor ID:</dt>
										<dd class="col-7">{{ user.sponsorID }}</dd>
										<dt class="col-5">Sponsor Username:</dt>
										<dd class="col-7">{{ user.sponsorUserName }}</dd>
										<dt class="col-5">Date Of Registration:</dt>
										<dd class="col-7">{{ user.registedOn }}</dd>
									</dl>
								</div>
							</div>
						</div>
						<div class="col-md-6 my-3">
							<div class="card">
								<div class="card-header">
									<h3 class="card-title">Contact Information</h3>
								</div>
								<div class="card-body">
									<dl class="row">
										<dt class="col-5">Email Address:</dt>
										<dd class="col-7">{{ user.emailAddress }}</dd>
									</dl>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>

    

</template>

<script>
import globalMixin from "@/views/globalMixin";
import InputField from "@/components/Input";
import PageHeader from "@/components/PageHeader";
import { mapGetters } from "vuex";
export default {
  mixins: [globalMixin],
  components: { InputField, PageHeader },
  data() {
    return {
      msg: { type: "Error", content: null, class: "alert-danger" },
      gettingData: false,
      walletAddress: "",
      form: {
        emailAddress: null,
        transactionPassword: null,
        walletAddress: null,
      },
      errors: {
        emailAddress: null,
        transactionPassword: null,
        walletAddress: null,
      },
    };
  },
  created() {
    this.form.emailAddress = this.user.emailAddress;
    this.form.walletAddress = this.user.walletAddress;
  },
  watch: {
    "form.emailAddress": function(newValue, oldValue) {
      this.validateFields(
        "Email Address",
        "emailAddress",
        newValue,
        /^[^@ ]+@[^@ ]+\.[^@ ]+$/
      );
    },
    "form.walletAddress": function(newValue, oldValue) {
      this.validateFields("Wallet Address", "walletAddress", newValue);
    },
    "form.transactionPassword": function(newValue, oldValue) {
      this.validateFields(
        "Transaction password",
        "transactionPassword",
        newValue
      );
    },
  },
  methods: {
    submit() {
      this.postData(this.form, `/member/profile/edit`);
    },
    successFunc() {
      this.$store.state.auth.user.emailAddress = this.form.emailAddress;
      if (this.form.walletAddress) {
        this.$store.state.auth.user.walletAddress = this.form.walletAddress;
      }
    },
  },
};
</script>
<style scoped>
.errorText {
  color: red !important;
}
.user-contact-info li {
  padding-left: 15px;
}
.infospan {
  margin-left: 15px;
}
.ul.stats li {
  white-space: normal !important;
}
.card-body{
  min-height: 300px;
}
</style>
