<template>
    <div class="page">
    
    <div v-if="this.$store.state.gettingData">
        <div id="overlayer" ></div>
        <div id="overlay3"  class="loader3">
          <div id="loading-wrapper">
            <div id="loader">
              <span class="loader-spinn"></span>
            </div>
          </div>
        </div>
    </div>
    

		 <Navbar v-if="isLoggedIn" />
		 <component v-bind:is="getRoute" :key="setKey" v-if="isLoggedIn"></component>
      <Footer />
      <SuccessModel />
	</div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import SuccessModel from '@/components/Success';
import Header from '@/components/Header';
import Navbar from '@/components/Navbar';

import Login from '@/views/Login.vue';

import Dashboard from '@/views/Dashboard';

import EditProfile from '@/views/profile/Edit.vue';
import LoginPassword from '@/views/profile/LoginPassword.vue';

import ProfilePhoto from '@/views/profile/Photo.vue';
import TransactionPassword from '@/views/profile/TransactionPassword.vue';

// import PackagePurchase from '@/views/package/Purchase.vue';
import StackingPurchase from '@/views/package/Stacking.vue';
import PackageHistory from '@/views/package/History.vue';


import LevelView from '@/views/network/Level.vue';
import LevelDetails from '@/views/network/LevelDetails.vue';
import DirectMember from '@/views/network/Member.vue';

import IncomeDirect from '@/views/income/Direct.vue';
import IncomeLevel from '@/views/income/Level.vue';
import IncomePackage from '@/views/income/Package.vue';
import IncomePackageMonthly from '@/views/income/MonthlyPackage.vue';

import WalletSummary from '@/views/wallet/Summary.vue';
import WalletTrnasferFund from '@/views/wallet/Fund.vue';
import WalletTransferHistory from '@/views/wallet/History.vue';

import TokenWalletSummary from '@/views/token/TokenWalletSummary.vue';

import TokenWalletLockedSummary from '@/views/token/TokenWalletLockedSummary.vue';


import ListMembers from '@/views/business/ListMembers.vue'
import totalBusiness from '@/views/business/TotalBusiness.vue'

import { mapGetters, mapMutations } from 'vuex';

import axios from 'axios';

export default {
  data() {
    return {
      comp: 'Dashboard',
      isLoggedIn: false
    };
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      getRoute: 'getRoute',
    }),
    setKey() {
      const rand = Math.random()
        .toString(16)
        .substr(2, 4);
      this.$store.state.currentComponentKey = `${this.getRoute}-${rand}`;

      return this.$store.state.currentComponentKey;
    },
  },
  created() {
    // get token from local storage
    let token = localStorage.getItem('authT');
    let tokenTime = localStorage.getItem('authExp');
    let now = Date.now();

    if (localStorage.getItem('cachecustom')) {
      axios.defaults.headers.common['cachecustom'] = true;
    }

    if (token && tokenTime > now) {
      this.$store.state.auth.token = localStorage.getItem('authT');
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('authT')}`;
      this.isLoggedIn = true;
      this.SET_ROUTE('Dashboard');
    } else {
      localStorage.removeItem('authT');
      localStorage.removeItem('authExp');
      // this.SET_ROUTE('Login');
      window.location.href = '/login.html';
      
    }
  },

  methods: {
    ...mapMutations(['SET_ROUTE']),
    applyCls(cls) {
      if (this.getRoute !== 'Login') {
        return cls;
      }
    },
  },
  components: {
    Footer,
    totalBusiness,
    ListMembers,
    SuccessModel,
    Header,
    Navbar,
    Login,
    Dashboard,
    EditProfile,
    LoginPassword,
    ProfilePhoto,
    TransactionPassword,
    StackingPurchase,
    PackageHistory,
    LevelView,
    LevelDetails,
    DirectMember,
    IncomeDirect,
    IncomePackage,
    IncomePackageMonthly,
    IncomeLevel,
    WalletSummary,
    WalletTrnasferFund,
    WalletTransferHistory,
    TokenWalletSummary,
    TokenWalletLockedSummary
  },
};
</script>

<style>

a {
  cursor: pointer;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.help-block {
  color: red;
  padding-top: -2px;
  padding-bottom: 10px;
  font-size: 12px;
}
.loader3 {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1021;
  background-color: rgba(0, 0, 0, 0.4);
}
#loading-wrapper #loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 53px;
  height: 53px;
  margin: auto;
  bottom: 0;
  right: 0;
  text-align: center;
}
.loader-spinn {
  width: 48px;
  height: 48px;
  border: 3px dotted #FFF;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}
.loader-spinn::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px dotted #ffc50c;
  border-style: solid solid dotted;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}
    
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
} 

</style>