<template>
  <div style="float: right; margin: 0 10px">
    <div class="form-group">
      <select
        class="form-control searchlen"
        id="sel1"
        v-model="search.type"
        @change="searchSelected"
        width="50px"
        style="display: inline-block"
      >
        <option value="" disabled>Search</option>
        <option
          style="padding: 10px"
          v-for="(item, index) in $store.state.tableHeaders[this.dataType].searchItems"
          :key="index"
          :value="item.value"
          >{{ item.name }}</option
        >
      </select>
      <select
        class="form-control searchlen"
        v-model="search.value"
        v-if="search.field && search.field === 'Select'"
        style="margin-left: 10px;display: inline-block"
      >
        <option value="" disabled>Select Option</option>
        <option
          v-for="(item, index) in $store.state.tableHeaders[this.dataType].searchSelectItems[search.type]"
          :key="index"
          :value="item"
          >{{ item }}</option
        >
      </select>
      <i
        v-if="search.field && search.field === 'Select'"
        class="fa fa-close"
        style="margin: 0px 10px; font-size: 18px"
        @click="cancelSearch"
      ></i>

      <input
        type="text"
        placeholder="search"
        v-model="search.value"
        style="display: inline-block"
        class="form-control searchInputbox "
        v-if="search.field && search.field === 'Input'"
      />
      <i v-if="search.field && search.field === 'Input'" class="fa fa-close closeIcon" @click="cancelSearch"></i>

      <datepicker-lite
        style="display: inline-block; padding-left: 10px"
        id="datepicker"
        placeholder="select date"
        class-attr="form-control searchInputbox"
        @value-changed="searchDateChanged"
        v-if="search.field && search.field === 'DatePicker'"
      ></datepicker-lite>
      <i v-if="search.field && search.field === 'DatePicker'" @click="cancelSearch" class="fa fa-close closeIcon "></i>

      <button class="btn btn-primary" style="display: inline-block; min-width: 40px;min-height: 30px" v-if="search.value" @click="dosearch">
        Ok
      </button>
    </div>
  </div>
</template>

<script>
import DatepickerLite from 'vue3-datepicker-lite';
export default {
  emits: ['clicked', 'cancel'],
  props: ['dataType'],
  components: { DatepickerLite },
  data() {
    return {
      search: { type: '', value: '', field: null },
    };
  },
  computed: {
    searchInputs() {
      this.$store.state.tableHeaders[this.dataType].search.inputs.forEach((item) => {
        if (item === this.search.type) {
          return true;
        }
      });
      return false;
    },
    searchDate() {
      this.$store.state.tableHeaders[this.dataType].search.date.forEach((item) => {
        if (item === this.search.type) {
          return true;
        }
      });
      return false;
    },
  },
  watch: {
    'search.value': function(newValue, oldValue) {
      if (this.search.value || this.search.field === 'DatePicker') {
        this.search.value = this.search.value.replace('/', '-');
      }
    },
  },
  methods: {
    dosearch() {
      this.$emit('clicked', this.search);
    },
    cancelSearch() {
      this.search = { type: '', value: null, field: null };
      this.$emit('cancel');
    },
    searchSelected() {

    

      if (!this.search.type) {
        this.search.field = null;
      } else if (
        this.search.type === 'createdOn' ||
        this.search.type === 'expireOn' ||
        this.search.type === 'achievedOn' ||
        this.search.type === 'fromDate' ||
        this.search.type === 'requestedOn' ||
        this.search.type === 'approveOrRejectedOn' ||
        this.search.type === 'lastUpdatedOn' ||
        this.search.type === 'unlockOn'
      ) {
        this.search.field = 'DatePicker';
      } else if (
        this.search.type === 'status' ||
        this.search.type === 'referenceType' ||
        this.search.type === 'approvedOrRejectedBy' ||
        this.search.type === 'purchaseType' ||
        this.search.type === 'trStatus'
      ) {
        this.search.field = 'Select';
      } else {
        this.search.field = 'Input';
      }
      this.search.value = '';
    },
    searchDateChanged(value) {
      this.search.value = value;
    },
  },
};
</script>

<style>
.searchlen {
  width: unset !important;
}
.picker__warp[data-v-0404364f] {
  border: 1px solid;
  background-color: #561b7d;
  border-radius: 10px;
  box-shadow: 3px 3px 15px rgb(86, 27, 125), -3px -3px 15px rgba(86, 27, 125, 0.62), inset 3px 3px 15px transparent, inset -3px -3px 15px transparent;
}
#datepicker input {
  height: unset !important;
  width: 150px !important;

  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #FFF;
  background-image: none;
  border-radius: 4px;
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
</style>
