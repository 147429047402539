import store from '@/store';
import axios from 'axios';

store.subscribe((mutiation) => {
  switch (mutiation.type) {
    case 'auth/SET_TOKEN':
      if (mutiation.payload) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${mutiation.payload}`;
      } else {
        axios.defaults.headers.common['Authorization'] = null;
      }
      break;
  }
});
