<template>
  <div class="page-wrapper">
    <PageHeader title="KRS Token" subTitle="KRS Locked Token Wallet" />

    <Table
      dataType="TokenWalletLockedSummary"
      url="token/locked/summary"
      title="KRS Locked Token Wallet"
    />
  </div>
</template>
<script>
import Table from "@/components/Table";
import PageHeader from "@/components/PageHeader.vue";

export default {
  components: { Table, PageHeader },
};
</script>
