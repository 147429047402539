<template>
  <div class="page-wrapper">
    <!-- <div v-if="true" id="overlay" style="display:block">
      <div id="loading-wrapper">
        <div id="loader">
          <img
            src="/images/loader1.gif"
            class="img-responsive"
            style="height: 200px; width: 450px"
          />
        </div>
      </div>
    </div> -->

    <div id="overlayer" v-if="gettingData"></div>
    <div id="overlay3"  v-if="gettingData" class="loader3">
      <div id="loading-wrapper">
        <div id="loader">
          <img
            src="/images/loader1.gif"
            class="img-responsive"
            style="height: 200px;width: 450px"
          />
        </div>
      </div>
    </div>
       <PageHeader title="Change Login Password" subTitle="Update password"/>
 
    <div class="page-body">
      <div class="container-xl">
        <div class="card">
          <div class="card-body">
            <div class="mb-3">
              <div
                v-if="msg.content"
                :class="msg.class"
                class="alert alert-dismissible"
                style="margin-top: 20px; font-size: 15px; display: block; background: red; color: white"
              >
                <i class="icon-warning2" style="color: white"></i>
                <a
                  href="#"
                  class="close"
                  data-dismiss="alert"
                  aria-label="close"
                  >&times;</a
                >
                <strong>{{ msg.type }} </strong>
                {{ msg.content }}.
              </div>
            </div>

            <div class="mb-3">
              <label class="form-label">Current Login Password</label>
              <input
                type="password"
                class="form-control"
                aria-label="pwd"
                aria-describedby="pwd"
                v-model="form.currentPassword"
              />
              <div v-if="errors.currentPassword" class="help-block">
                {{ errors.currentPassword }}
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">New Login Password</label>
              <input
                type="password"
                class="form-control"
                aria-label="loginPassword"
                aria-describedby="loginPassword"
                fieldName="loginPassword"
                placeholder="New Login Password"
                v-model="form.loginPassword"
                key="loginPassword"
              />
              <div v-if="errors.loginPassword" class="help-block">
                {{ errors.loginPassword }}
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Confirm Password</label>
              <input
                type="password"
                class="form-control"
                fieldName="confirmPassword"
                placeholder="Confirm Password"
                v-model="form.confirmPassword"
              />
              <div v-if="errors.confirmPassword" class="help-block">
                {{ errors.confirmPassword }}
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row align-items-center">
              <div class="col-auto">
                <a
                  @click="postData(form, `/member/change-password`)"
                  class="btn btn-primary"
                  >Update</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputField from "@/components/Input";
import globalMixin from "@/views/globalMixin";
import PageHeader from "@/components/PageHeader";

export default {
  props: ["dataType", "title"],
  mixins: [globalMixin],
  components: { InputField,PageHeader },
  data() {
    return {
      msg: { type: "Error", content: "", class: "alert-danger" },
      gettingData: false,
      form: {
        currentPassword: null,
        loginPassword: null,
        confirmPassword: null,
      },
      errors: {
        currentPassword: null,
        loginPassword: null,
        confirmPassword: null,
      },
    };
  },

  watch: {
    "form.currentPassword": function(newValue, oldValue) {
      this.validateFields(
        "Current Login password",
        "currentPassword",
        newValue
      );
    },
    "form.loginPassword": function(newValue, oldValue) {
      this.validateFields(
        "Login password",
        "loginPassword",
        newValue,
        /(?=.*\d.*)(?=.*[A-Z].*).(?=.*[a-z].*).{8,20}/
      );
    },

    "form.confirmPassword": function(newValue, oldValue) {
      if (!newValue) {
        this.errors.confirmPassword = "Please enter confirm password";
      } else if (this.form.confirmPassword !== this.form.loginPassword) {
        this.errors.confirmPassword = "Password do not match";
      } else {
        this.errors.confirmPassword = null;
      }
      this.msg.content = null;
    },
  },
};
</script>

<style scoped>
.help-block {
  margin-top: 5px;
}
#overlayer {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 11;
  background: transparent;
}
.loader3 {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
