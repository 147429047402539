<template>
  <div class="page-wrapper">
    <PageHeader  title="Dashboard" subTitle="Overview" />
    <div class="page-body">
      <div class="container-xl">
        <div class="row row-cards">
          <div class="col-lg-6 my-3">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Account Details</h3>
              </div>
              <div class="card-body">
                <dl class="row">
                  <dt class="col-12 col-lg-5">Name:</dt>
                  <dd class="col-12 col-lg-7 mt-1 mb-3">{{ this.user.firstName }}</dd>
                  <dt class="col-12 col-lg-5">Member ID:</dt>
                  <dd class="col-12 col-lg-7 mt-1 mb-3">{{ this.user.memberID }}</dd>
                  <dt class="col-12 col-lg-5">User Name:</dt>
                  <dd class="col-12 col-lg-7 mt-1 mb-3">{{ this.user.userName }}</dd>
                  <dt class="col-12 col-lg-5">Referral Link:</dt>
                  <dd class="col-12 col-lg-7 mt-1 mb-3">
                    {{
                      `https://login.krstoken.com/register.html#/referral/${this.user.userName}`
                    }}
                    <a
                      id="copyLink"
                      class="no-hover"
                      @click="copyLink('businessRef')"
                      style="margin-left: 5px;color: blue; text-decoration: underline"
                      >COPY</a
                    >
                    <a
                      @click="openLink('businessRef')"
                      style="margin-left: 5px;color: blue; text-decoration: underline"
                      >OPEN</a
                    >
                  </dd>

                  <dt class="col-12 col-lg-5">KRS Token Address:</dt>
                  <dd class="col-12 col-lg-7 mt-1 mb-3"><a @click="openWalletAdd(this.user.walletAddress)">{{ this.user.walletAddress }}</a></dd>

                  <dt class="col-12 col-lg-5">KRS Locked Token Address:</dt>
                  <dd class="col-12 col-lg-7 mt-1 mb-3"><a @click="openWalletAdd(this.user.lockedWalletAdd)"> {{ this.user.lockedWalletAdd }}</a></dd>

                  <dt class="col-12 col-lg-5">Email Address:</dt>
                  <dd class="col-12 col-lg-7 mt-1 mb-3">{{ this.user.emailAddress }}</dd>
                  <dt class="col-12 col-lg-5">Sponsor Details:</dt>
                  <dd class="col-12 col-lg-7 mt-1 mb-3">
                    {{ this.user.sponsorID }} ({{ this.user.sponsorUserName }})
                  </dd>

                  <dt class="col-12 col-lg-5">Registered On:</dt>
                  <dd class="col-12 col-lg-7 mt-1">{{ this.user.registedOn }}</dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="col-lg-6 my-3">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Business Information</h3>
              </div>
              <div class="card-body">
                <dl class="row">
                  <dt class="col-12 col-lg-6">Total Investment:</dt>
                  <dd class="col-12 col-lg-6 mt-1 mb-3">{{ this.user.totalInvestment }}</dd>
                  <dt class="col-12 col-lg-6">Total Team Investment:</dt>
                  <dd class="col-12 col-lg-6 mt-1 mb-3">{{ this.user.totalTeamInvestment }}</dd>
                  <dt class="col-12 col-lg-6">Total Team Members:</dt>
                  <dd class="col-12 col-lg-6 mt-1 mb-3">{{ this.user.totalTeamMembers }}</dd>
                  <dt class="col-12 col-lg-6">Total Package Bonus:</dt>
                  <dd class="col-12 col-lg-6 mt-1 mb-3">{{ this.user.totalPackageBonus }}</dd>
                  <dt class="col-12 col-lg-6">Total Level Bonus:</dt>
                  <dd class="col-12 col-lg-6 mt-1 mb-3">{{ this.user.totalLevelBonus }}</dd>
                  <dt class="col-12 col-lg-6">Total Referral Bonus:</dt>
                  <dd class="col-12 col-lg-6 mt-1 mb-3">{{ this.user.totalReferralBonus }}</dd>
                  <dt class="col-12 col-lg-6">Wallet Balance:</dt>
                  <dd class="col-12 col-lg-6 mt-1 mb-3">${{ this.user.totalWalletBalance }}</dd>
                  <dt class="col-12 col-lg-6">KRS Active Token Wallet Balance:</dt>
                  <dd class="col-12 col-lg-6 mt-1 mb-3">{{ this.user.krsTokenBalance }}</dd>
                   <dt class="col-12 col-lg-6">KRS Stacking Token Wallet Balance:</dt>
                  <dd class="col-12 col-lg-6 mt-1">{{ this.user.krsLockedTokenBalance }}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import globalMixin from "@/views/globalMixin";
import PageHeader from "@/components/PageHeader";


// import $ from "jquery";

export default {
  mixins: [globalMixin],
  components: { PageHeader },
  data() {
    return {
      gettingData: false,
      chartData: {
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
          // {
          //   label: 'Return BONUS',
          //   backgroundColor: '#76BC40',
          //   data: [25, 0, 0, 0, 0, 0, 0, 0, 0],
          // },
          // {
          //   label: 'BINARY BONUS',
          //   backgroundColor: '#286DD0',
          //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
          // },
          // {
          //   label: 'DIRECT BONUS',
          //   backgroundColor: '#202020',
          //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
          // },
          {
            label: "LEVEL BONUS",
            backgroundColor: "#A98C49",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
      },
    };
  },
  methods: {
    openWalletAdd(address){
         window.open(`${this.$store.state.gdccScanURL}/address/${address}`, `_gdc_${this.user.userName}`);
    },
    copyLink(type) {
      let url;

      if (type === "businessRef") {
        url = `https://login.krstoken.com/register.html#/referral/${this.user.userName}`;
      } else {
        url = `https://login.krstoken.com/affiliate.html#/${this.user.userName}`;
      }

      navigator.clipboard.writeText(url);

      $("#copyLink").tooltip("show");
    },
    openLink(type) {
      let url;
      if (type === "businessRef") {
        url = `https://login.krstoken.com/register.html#/referral/${this.user.userName}`;
      } else {
        url = `https://login.krstoken.com/affiliate.html#/${this.user.userName}`;
      }
      window.open(url, `_${this.user.userName}`);
    },
    ...mapMutations(["SET_ROUTE"]),
    changePage(page) {
      this.SET_ROUTE(page);
      // hide sidebar
      document.getElementById("html").classList.remove("left-sidebar-open");
    },
  },
  mounted() {},
  async created() {
    // coin rate animation

    // coin rate animation end

    //if (!this.$store.state.auth.user.firstName) {

    let res = null;
    res = await this.getData("/member/profile");

    if (res.status && res.status === "success") {
      this.$store.state.auth.user = res.data;

      this.chartData = this.$store.state.auth.user.chartData;
      // console.log(this.myChart);
      // $('#listingCoin').modal('show');
    } else {
      this.SET_ROUTE("Login");
      window.location.href = "/login.html";
    }

    // var ctx = document
    //   .getElementById("WeeklyPerformanceChart")
    //   .getContext("2d");

    // var myChart = new Chart(ctx, {
    //   type: "line",
    //   data: this.chartData,
    //   options: {
    //     fill: false,
    //     tooltips: {
    //       displayColors: false,
    //       callbacks: {
    //         mode: "x",
    //       },
    //     },
    //     scales: {
    //       xAxes: [
    //         {
    //           stacked: false,
    //           gridLines: {
    //             display: false,
    //           },
    //         },
    //       ],
    //       yAxes: [
    //         {
    //           gridLines: {
    //             display: false,
    //           },
    //           stacked: false,
    //           ticks: {
    //             beginAtZero: true,
    //           },
    //           type: "linear",
    //         },
    //       ],
    //     },
    //     responsive: true,
    //     maintainAspectRatio: false,
    //     legend: { position: "bottom" },
    //   },
    // });

    if (this.$store.state.loadCSS) {
      ///
    }
    this.$store.state.loadCSS = false;
    // hide captcha on other pages

    let elem = document.getElementsByClassName("grecaptcha-badge");

    if (elem[0]) {
      elem[0].style.display = "none";
    }
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
};
</script>

<style scoped>
.card-body{
  min-height: 480px;
}
a.dashboard-slider-action {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background: #007ae1;
  color: #ffffff;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
}

.chart-height {
  width: 100%;
  overflow: auto;
}
@media (max-width: 576px) {
  .main-heading .right-actions {
    display: block;
    float: none;
    margin: 15px 0 10px;
  }
}

#WeeklyPerformanceChart {
  height: 277px !important;
}
.refLink {
  color: blue;
  cursor: pointer;
  margin-left: 10px;
}

.refLink:hover {
  border-bottom: 1px solid blue;
}

.scroll-stats {
  text-align: center;
  margin: unset;
}
.memberinfospan {
  margin-left: 10px;
}
.carousel-item {
  cursor: pointer;
}
</style>
