<template>
  <div class="page-wrapper">
    <PageHeader title="Package" subTitle="Purchase Package" />

    <div class="page-body">
      <div class="container-xl">
        
          
          <div class="main-content">
            <div class="row gutter" v-if="user.canPurchasePackage === 'No'">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="panel  b-primary bt-sm ">
                  <div class="panel-content">
                    <div
                      class="widget-list list-sm list-left-element widgetCls"
                    >
                      <ul style="margin:0;padding:30px;list-style:none;text-align:center;">
                        <!--  alert -->
                        <li>
                          <div
                            class="alert alert-info"
                            style="font-size:30px;color:white;font-weight:bold;display:flex;align-items:center;justify-content:center;height:250px;max-width:800px;margin:0 auto;text-align:center;"                          >
                            Purchase package is under maintenance at this
                            moment. Please try again later.
                          </div>
                        </li>
                        <!-- alert end -->
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else>
                <div
                    v-if="msg.content"
                    :class="msg.class"
                    class="alert alert-dismissible"
                    style=" font-size: 15px; display: block; background: red; color: white"
                  >
                    <a @click="msg.content = ''"
                      style="color: white; font-size: 17px"
                      href="#"
                      class="close"
                      data-dismiss="alert"
                      aria-label="close"
                      >&times;</a
                    >
                    <strong style="padding-left: 10px; ">{{ msg.type }}</strong> {{ msg.content }}.
                  </div>
              <div class="row">
               
                <!-- alert end -->
                <!-- <div class="col-md-6 col-lg-1"></div> -->
                <div class="col-md-4 col-lg-4  my-2">
                  <div class="card">                 
                    <div class="card-body">
                      <div class="text-center">
                        <h4 class="pak-title">USD-100</h4>
                        <h5 style="margin: 16px 0;display: inline-block;width: 100%;">
                        Get KRS Token worth of package amount which will be staking for 365 days.
                        </h5>
                        <hr>
                        <button
                          class="btn  btn-primary"
                          style="margin-top: 10px;margin-bottom: 16px"
                          @click="purchasePackage('USD-100')"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4  my-2">
                  <div class="card">                 
                    <div class="card-body">
                      <div class="text-center">
                        <h4 class="pak-title">USD-500</h4>
                        <h5 style="margin: 16px 0;display: inline-block;width: 100%;">
                          GGet KRS Token worth of package amount which will be staking for 365 days.
                        </h5>
                        <hr>
                        <button
                          class="btn  btn-primary"
                          style="margin-top: 10px;margin-bottom: 16px"
                          @click="purchasePackage('USD-500')"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4  my-2">
                  <div class="card">                 
                    <div class="card-body">
                      <div class="text-center">
                        <h4 class="pak-title">USD-1000</h4>
                        <h5 style="margin: 16px 0;display: inline-block;width: 100%;">
                         Get KRS Token worth of package amount which will be staking for 365 days.
                        </h5>
                        <hr>
                        <button
                          class="btn  btn-primary"
                          style="margin-top: 10px;margin-bottom: 16px"
                          @click="purchasePackage('USD-1000')"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
               
                <!-- alert end -->
                <!-- <div class="col-md-6 col-lg-1"></div> -->
                <div class="col-md-4 col-lg-4 my-2">
                  <div class="card">
                    <div class="card-body">
                      <div class="text-center">
                        <h4 class="pak-title">USD-5000</h4>
                        <h5 style="margin: 16px 0;display: inline-block;width: 100%;">
                         Get KRS Token worth of package amount which will be staking for 365 days.
                        </h5>
                        <hr>
                        <button
                          class="btn  btn-primary"
                          style="margin-top: 10px;margin-bottom: 16px"
                          @click="purchasePackage('USD-5000')"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-4  my-2">
                  <div class="card">
                    <div class="card-body">
                      <div class="text-center">
                        <h4 class="pak-title">USD-10000</h4>
                        <h5 style="margin: 16px 0;display: inline-block;width: 100%;">
                        Get KRS Token worth of package amount which will be staking for 365 days.
                        </h5>
                        <hr>
                        <button
                          class="btn  btn-primary"
                          style="margin-top: 10px;margin-bottom: 16px"
                          @click="purchasePackage('USD-10000')"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4  my-2">
                  <div class="card">
                    <div class="card-body">
                      <div class="text-center">
                        <h4 class="pak-title">USD-17000</h4>
                        <h5 style="margin: 16px 0;display: inline-block;width: 100%;">
                        Get KRS Token worth of package amount which will be staking for 365 days.</h5>
                        <hr>
                        <button
                          class="btn  btn-primary"
                          style="margin-top: 10px;margin-bottom: 16px"
                          @click="purchasePackage('USD-17000')"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Row start -->
          </div>
          <!-- Row end -->
        </div>
      
    </div>

    <!-- popup start-->
    <div
      class="modal fade"
      id="buyNowPackage"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalDefault"
    >
      <div class="modal-dialog" role="document" style="margin-top: 200px">
        <div class="modal-content" style="border: none">
          <div
            class="modal-header state modal-primary"
            style="background: #561b7d"
          >
            <h4
              class="modal-title"
              id="buyPackageTitle"
              style="color: white; font-weight: bold"
            >
              Purchase Package - {{ this.form.packageType}}
            </h4>

            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <!--<h5><b>Cash Wallet Balance : </b></h5>-->
            <p class="modal-title h6">
              Please note that
              <strong><span id="package-amt">amount</span></strong>
              will be deducted from your cash wallet when you click on submit.
              <br /><br />
              Your current wallet balance is :
              <b> ${{ this.user.totalWalletBalance }} </b>
            </p>

            <br />
            <div class="form-group" id="pacakgeTranPass">
             
              <input
                class="form-control"
                type="password"
                placeholder="Transaction Password"
                id="transactionPassword"
                name="transactionPassword"
                v-model="form.transactionPassword"
                autocomplete="none"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              class="btn btn-primary "
              id="pacakgeValidBtn"
              @click="doPurchase"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- popup end -->
  </div>
</template>

<script>
import globalMixin from "@/views/globalMixin";
import { mapGetters, mapMutations } from "vuex";
import PageHeader from "@/components/PageHeader.vue";

export default {
  components: { PageHeader },
  mixins: [globalMixin],
  data() {
    return {
      gettingData: false,
      modelDilog: false,
      form: { packageType: null, transactionPassword: null },
      errors: {  transactionPassword: null },
      msg: { type: "Error", content: "", class: "alert-danger" },
    };
  },
  created() {},

  computed: {
    ...mapGetters({
      getPurchasePackages: "getPurchasePackages",
    }),
  },

  methods: {
    validateAmount() {
      // let regEx = /\d/;
      // if (this.form.amount) {
      //   if (regEx.test(!this.form.amount)) {
      //     this.errors.amount = "Please enter valid amount";
      //   } else if (this.form.amount < 10) {
      //     this.errors.amount = "Minimum package amount is $10";
      //   } else if (this.form.amount > 70000) {
      //     this.errors.amount = "Minimum package amount is $70000";
      //   } else {
      //     this.errors.amount = "";
      //   }
      // } else {
      //   this.errors.amount = "";
      // }
    },
    async doPurchase() {
      // this just for setting the data and closing the model not for validation. this will override mixin function
      this.errors.amount = "";
      $("#buyNowPackage").modal("toggle");
      this.postData(this.form, "/packages");
    },

    purchasePackage(plan) {
      this.msg.content = "";
      this.form.packageType = plan;
      $("#buyNowPackage").modal("toggle");
    },
    svgiconid(index, id = false) {
      if (id) {
        return `s${index + 1}-text`;
      }
      return `#s${index + 1}-text`;
    },
  },
};
</script>

<style>
/* Main styles */
/* @import url('/css/google-fonts/fonts.css?family=Open+Sans:800'); */

.newlineItem {
  margin-top: 30px;
}
.text {
  fill: none;
  stroke-width: 9;
  stroke-linejoin: round;
  stroke-dasharray: 70 330;
  stroke-dashoffset: 0;
  -webkit-animation: stroke 6s infinite linear;
  animation: stroke 6s infinite linear;
}
.text:nth-child(5n + 1) {
  stroke: #fe0a00;
  -webkit-animation-delay: -1.2s;
  animation-delay: -1.2s;
}
.text:nth-child(5n + 2) {
  stroke: #f5a503;
  -webkit-animation-delay: -2.4s;
  animation-delay: -2.4s;
}
.text:nth-child(5n + 3) {
  stroke: #000000;
  -webkit-animation-delay: -3.6s;
  animation-delay: -3.6s;
}
.text:nth-child(5n + 4) {
  stroke: #3b76a0;
  -webkit-animation-delay: -4.8s;
  animation-delay: -4.8s;
}
.text:nth-child(5n + 5) {
  stroke: #3eb15b;
  -webkit-animation-delay: -6s;
  animation-delay: -6s;
}
@-webkit-keyframes stroke {
  100% {
    stroke-dashoffset: -400;
  }
}
@keyframes stroke {
  100% {
    stroke-dashoffset: -400;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.modal-title {
  color: #000;
}
.btn-close {
  background-color: #fff;
}

.pak-title{position: relative;font-size: 20px;padding-bottom:20px}
.pak-title::before{content: ''; position: absolute; width: 50px; height: 5px; border-radius: 6px; background-color: #cd007f; left: 0; bottom: 0; right: 0; margin: 0 auto;}
</style>
