<template>
  <div class="page-wrapper">
    <PageHeader title="Team" subTitle="View Levels" />

    <Table dataType="levelView" url="network/level" title="Level View">
      <!-- action icon link goes here -->
      <template v-slot:default="slotProps">
        <i
          @click="action1Func(slotProps.data.level)"
          class="fa fa-eye eyeIcon"
          aria-hidden="true"
        ></i>
      </template>
    </Table>
  </div>
</template>
<script>
import Table from "@/components/Table";
import PageHeader from "@/components/PageHeader.vue";

export default {
  components: { Table, PageHeader },
  methods: {
    action1Func(level) {
      this.$store.state.levelDetails = [];
      this.$store.state.tableHeaders.levelDetails.levelNo = level;
      this.$store.state.currentComponent = "LevelDetails";
    },
  },
};
</script>

<style>
.eyeIcon {
  cursor: pointer;
  font-size: 16px;
}
</style>
