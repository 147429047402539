<template>
  <div class="page-wrapper">
    <PageHeader title="Bonus" subTitle="View Package Bonus" />

    <Table
      dataType="incomePackage"
      url="income/package"
      title="View Package Bonus"
    />
  </div>
</template>
<script>
import Table from "@/components/Table";
import PageHeader from "@/components/PageHeader";

export default {
  components: { Table, PageHeader },
};
</script>
