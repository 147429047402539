<template>
  <div class="page-wrapper">
    <PageHeader title="Bonus" subTitle="View Direct Referal Bonus" />

    <Table dataType="incomeDirect" url="income/direct" title="View Direct Referal Bonus" />
  </div>
</template>
<script>
import Table from "@/components/Table";
import PageHeader from "@/components/PageHeader.vue";

export default {
  components: { Table, PageHeader },
};
</script>

