<template>
  <div class="page-wrapper">
    <PageHeader title="Business" subTitle="View Today's Registrations" />

    <Table
      dataType="ListMembers"
      url="business/member/list"
      title="View Today's Registrations"
    />
  </div>
</template>
<script>
import Table from "@/components/Table";
import PageHeader from "@/components/PageHeader.vue";

export default {
  components: { Table, PageHeader },
};
</script>
