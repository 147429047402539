<template>
  <div class="page-wrapper">
    <PageHeader title="Bonus" subTitle="View Levle Bonus" />

    <Table dataType="incomeLevel" url="income/level" title="View Levle Bonus" />
  </div>
</template>
<script>
import Table from "@/components/Table";
import PageHeader from "@/components/PageHeader.vue";

export default {
  components: { Table, PageHeader },
};
</script>
