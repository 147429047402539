<template>
  <div class="page-wrapper">
    <PageHeader title="Team" subTitle="View Levle Details" />

    <Table dataType="levelDetails" url="network/level-details" />
  </div>
</template>
<script>
import Table from "@/components/Table";
import PageHeader from "@/components/PageHeader.vue";

export default {
  components: { Table, PageHeader },
};
</script>
