<template>
    <div class="container-xl">
      <div class="page-header d-print-none">
        <div class="row g-2 align-items-center">
          <div class="col">
            <div class="page-pretitle">{{ subTitle }}</div>
            <h2 class="page-title">{{ title }}</h2>
          </div>
          <div class="col-12 col-md-auto ms-auto d-print-none">
            <div class="btn-list align-items-center">
              <b>KRS Token Rate:</b>
              <a
                href="#"
                class="btn btn-primary d-inline-block"
                data-bs-toggle="modal"
                data-bs-target="#modal-report"
                >$ {{ this.user.coinRate }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex';
export default {
    props: [ 'title', 'subTitle'],
    data(){
        return {

        }
    },
    computed: {
    ...mapGetters({
      user: 'auth/user',
      })
    }
}
</script>