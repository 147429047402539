<template>
  <div class="photo">
    <!-- Main container starts -->
    <div class="main-container">
      <div v-if="gettingData" id="overlay" style="display:block; z-index: 2000000">
        <div id="loading-wrapper">
          <div id="loader">
            <img src="/images/loader1.gif" class="img-responsive" style="height: 200px;width: 450px" />
          </div>
        </div>
      </div>
      <div class="row gutter">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <center>
            <img v-if="user.photo" alt="User photo" :src="'/data/' + user.photo" />
            <img v-else alt="User photo" src="/images/default-photo.png" />
            <p style="margin-top: 20px">
              Recommended photo size is 600X600 pixel
            </p>

            <!--  alert -->

            <div
              v-if="msg.content"
              :class="msg.class"
              class="alert alert-dismissible"
              style="margin-top: 20px; font-size: 15px"
            >
              <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
              <strong>{{ msg.type }}</strong> {{ msg.content }}.
            </div>

            <!-- alert end -->

            <form method="post" enctype="multipart/form-data" action="/upload">
              <input type="hidden" name="msgtype" value="2" />
              <input type="file" ref="file" id="file" name="profileImage" @change="handleFileUpload" />
              <p v-if="!file" style="color: red;margin-top: 5px">Please Select file</p>
              <input
                type="submit"
                @click.prevent="postData"
                value="Upload"
                class="btn"
                style="background: #EFF0F1;border: .2px solid black; margin-right: 10px; "
              />
              <input
                type="submit"
                @click.prevent="deletePhoto"
                value="Delete"
                class="btn btn-danger"
                v-if="user.photo"
                style="padding: 7px 15px"
              />
            </form>
          </center>
        </div>
      </div>
    </div>

    <!-- Main container ends -->
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import globalMixin from '@/views/globalMixin';
export default {
  mixins: [globalMixin],
  data() {
    return {
      file: '',
      gettingData: false,
      msg: { type: 'Error', content: null, class: 'alert-danger' },
    };
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
  },
  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    toggleMsgModel(type, msg, logout) {
      this.$store.state.MsgModel.type = type;
      this.$store.state.MsgModel.msg = msg;
      if (logout) {
        this.$store.state.MsgModel.logout = logout;
      }
      $('#successModel').modal('toggle');
    },
    reloadComponent() {
      this.$store.state.currentComponentKey = `${this.getRoute}-${Math.random()
        .toString(16)
        .substr(2, 4)}`;
    },
    async deletePhoto() {
      try {
        if (!this.gettingData && this.isLoggedInAsDifferentAcc()) {
          this.gettingData = true;

          const result = await axios.post(`${this.$store.state.baseURL}/member/profile/delete`);

          if (result.data.status === 'success') {
            this.$store.state.auth.user.photo = '';
            this.toggleMsgModel('Success', result.data.msg);
            this.reloadComponent();
          } else {
            this.toggleMsgModel('Error', 'Something went wrong. Please try again later');
          }
        }
        this.gettingData = false;
      } catch (err) {
        if (err) {
          const { response } = err;
          // Internet is not working or api cannot be connected
          if (!response) {
            this.toggleMsgModel('Error', `Network error. Please click 'OK' to reload the page and try again`, true);
          } else {
            const { request, ...errorObject } = response;
            if (errorObject.status && errorObject.status === 401) {
              this.toggleMsgModel('Error', `You are logged out. Please click 'OK' to login again`, true);
            } else if (
              errorObject.status &&
              errorObject.status === 500 &&
              response.data.error.code === 'LIMIT_FILE_SIZE'
            ) {
              this.msg.content = 'Tool large file. File must not be larger then 8MB';
            } else if (errorObject.status && errorObject.status === 401) {
              this.toggleMsgModel('Error', `You are logged out. Please click 'OK' to login again`, true);
            } else {
              this.toggleMsgModel(
                'Error',
                `Something went wrong. Please click 'OK' to reload the page and try again`,
                true
              );
            }
          }
        }
        this.gettingData = false;
      }
    },
    async postData() {
      try {
        if (!this.gettingData) {
          this.gettingData = true;

          let formData = new FormData();

          if (!this.file) {
          } else {
            formData.append('profileImage', this.file);
            const result = await axios.post(`${this.$store.state.baseURL}/member/profile/upload`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });

            if (result.data.status === 'success') {
              this.$store.state.auth.user.photo = result.data.data;
              this.toggleMsgModel('Success', result.data.msg);
              this.reloadComponent();
            } else {
              this.toggleMsgModel('Error', 'Something went wrong. Please try again later');
            }
          }
        }
        this.gettingData = false;
      } catch (err) {
        if (err) {
          const { response } = err;
          // Internet is not working or api cannot be connected
          if (!response) {
            this.toggleMsgModel('Error', `Network error. Please click 'OK' to reload the page and try again`, true);
          } else {
            const { request, ...errorObject } = response;
            if (errorObject.status && errorObject.status === 401) {
              this.toggleMsgModel('Error', `You are logged out. Please click 'OK' to login again`, true);
            } else if (
              errorObject.status &&
              errorObject.status === 500 &&
              response.data.error.code === 'LIMIT_FILE_SIZE'
            ) {
              this.msg.content = 'Tool large file. File must not be larger then 8MB';
            } else if (errorObject.status && errorObject.status === 401) {
              this.toggleMsgModel('Error', `You are logged out. Please click 'OK' to login again`, true);
            } else {
              this.toggleMsgModel(
                'Error',
                `Something went wrong. Please click 'OK' to reload the page and try again`,
                true
              );
            }
          }
        }
        this.gettingData = false;
      }
    },
  },
};
</script>
