<template>
  <div class="page-wrapper">
    <PageHeader title="Wallet" subTitle="View Fund Transfer History" />

    <Table
      dataType="walletTransferHistory"
      url="wallet/transfer-history"
      title="View Fund Transfer History"
    />
  </div>
</template>
<script>
import Table from "@/components/Table";
import PageHeader from "@/components/PageHeader.vue";

export default {
  components: { Table, PageHeader },
};
</script>
