<template>
  <div class="sticky-top">
			<header class="navbar navbar-expand-md navbar-light d-print-none">
				<div class="container-xl">
					<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
						<span class="navbar-toggler-icon"></span>
					</button>
					<h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
						<a href="index.html"><img src="images/logo.png" title="KRS Token" alt="KRS Token" width="50" height="50" class="img-fluid me-2" /><span>KRS Token</span></a>
					</h1>
					<div class="navbar-nav flex-row order-md-last">
						<!-- <div class="d-none d-md-flex">
							<div class="nav-item dropdown d-none d-md-flex me-3">
								<a href="#" class="nav-link px-0" data-bs-toggle="dropdown" tabindex="-1" aria-label="Show notifications">
									<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" /><path d="M9 17v1a3 3 0 0 0 6 0v-1" /></svg>
									<span class="badge bg-red"></span>
								</a>
								<div class="dropdown-menu dropdown-menu-arrow dropdown-menu-end dropdown-menu-card">
									<div class="card notificat-box">
										<div class="card-header">
											<h3 class="card-title">Last updates</h3>
										</div>
										<div class="list-group list-group-flush list-group-hoverable">
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col-auto"><span class="status-dot status-dot-animated bg-red d-block"></span></div>
													<div class="col text-truncate">
														<a href="#" class="text-body d-block">Example 1</a>
														<div class="d-block text-muted text-truncate mt-n1">
															Change deprecated html tags to text decoration classes (#29604)
														</div>
													</div>
													<div class="col-auto">
														<a href="#" class="list-group-item-actions">
															<svg xmlns="http://www.w3.org/2000/svg" class="icon text-muted" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" /></svg>
														</a>
													</div>
												</div>
											</div>
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col-auto"><span class="status-dot d-block"></span></div>
													<div class="col text-truncate">
														<a href="#" class="text-body d-block">Example 2</a>
														<div class="d-block text-muted text-truncate mt-n1">
															justify-content:between ⇒ justify-content:space-between (#29734)
														</div>
													</div>
													<div class="col-auto">
														<a href="#" class="list-group-item-actions show">
															<svg xmlns="http://www.w3.org/2000/svg" class="icon text-yellow" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" /></svg>
														</a>
													</div>
												</div>
											</div>
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col-auto"><span class="status-dot d-block"></span></div>
													<div class="col text-truncate">
														<a href="#" class="text-body d-block">Example 3</a>
														<div class="d-block text-muted text-truncate mt-n1">
															Update change-version.js (#29736)
														</div>
													</div>
													<div class="col-auto">
														<a href="#" class="list-group-item-actions">
															<svg xmlns="http://www.w3.org/2000/svg" class="icon text-muted" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" /></svg>
														</a>
													</div>
												</div>
											</div>
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col-auto"><span class="status-dot status-dot-animated bg-green d-block"></span></div>
													<div class="col text-truncate">
														<a href="#" class="text-body d-block">Example 4</a>
														<div class="d-block text-muted text-truncate mt-n1">
															Regenerate package-lock.json (#29730)
														</div>
													</div>
													<div class="col-auto">
														<a href="#" class="list-group-item-actions">
															<svg xmlns="http://www.w3.org/2000/svg" class="icon text-muted" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" /></svg>
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div> -->
						<div class="nav-item dropdown">
							<a href="#" class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
								<!-- <span class="avatar avatar-sm" style="background-image: url(images/043f.jpg)"></span> -->
								<div class="d-xl-block ps-2">
									<div>{{ this.user.firstName }}</div>
									<div class="mt-1 small">{{ this.user.memberID }} ({{ this.user.userName}})</div>
								</div>
							</a>
							<div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
							    <a @click="logout" class="dropdown-item">Logout</a>
							</div>
						</div>
					</div>
				</div>
			</header>
			<div class="navbar-expand-md">
				<div class="collapse navbar-collapse" id="navbar-menu">
					<div class="navbar navbar-light">
						<div class="container-xl">
							<ul class="navbar-nav">
								<li class="nav-item" :class="activeLink('dashbaord')" >
									<a class="nav-link" href="index.html" >
										<span class="nav-link-icon d-md-none d-lg-inline-block">
											<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><polyline points="5 12 3 12 12 3 21 12 19 12" /><path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" /><path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg>
										</span>
										<span class="nav-link-title">Dashboard</span>
									</a>
								</li>
								<li class="nav-item dropdown" :class="activeLink('profile')">
									<a class="nav-link dropdown-toggle" href="#navbar-base" data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-expanded="false" >
										<span class="nav-link-icon d-md-none d-lg-inline-block">
											<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="9"></circle><circle cx="12" cy="10" r="3"></circle><path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855"></path></svg>
										</span>
										<span class="nav-link-title">Profile</span>
									</a>
									<div class="dropdown-menu">
										<div class="dropdown-menu-columns">
											<div class="dropdown-menu-column">
												<a class="dropdown-item" @click="changePage('EditProfile')">Edit Profile</a>
												<a class="dropdown-item" @click="changePage('LoginPassword')">Change Login Password</a>
												<a class="dropdown-item" @click="changePage('TransactionPassword')">Change Transaction Password</a>
											</div>
										</div>
									</div>
								</li>
								<li class="nav-item dropdown" :class="activeLink('business')">
									<a class="nav-link dropdown-toggle" href="#navbar-business" data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-expanded="false" >
										<span class="nav-link-icon d-md-none d-lg-inline-block">
											<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><ellipse cx="16" cy="6" rx="5" ry="3"></ellipse><path d="M11 6v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path><path d="M11 10v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path><path d="M11 14v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path><path d="M7 9h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5"></path><path d="M5 15v1m0 -8v1"></path></svg>
										</span>
										<span class="nav-link-title">Business</span>
									</a>
									<div class="dropdown-menu">
										<div class="dropdown-menu-columns">
											<div class="dropdown-menu-column">
												<a class="dropdown-item" @click="changePage('ListMembers')">New Team Members</a>
												<a class="dropdown-item" @click="changePage('totalBusiness')">Team Business</a>
											</div>
										</div>
									</div>
								</li>
								<li class="nav-item dropdown" :class="activeLink('package')">
									<a class="nav-link dropdown-toggle" href="#navbar-extra" data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-expanded="false" >
										<span class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/star -->
											<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="6" cy="19" r="2"></circle><circle cx="17" cy="19" r="2"></circle><path d="M17 17h-11v-14h-2"></path><path d="M6 5l14 1l-1 7h-13"></path></svg>
										</span>
										<span class="nav-link-title">Package</span>
									</a>
									<div class="dropdown-menu">
										<div class="dropdown-menu-columns">
											<div class="dropdown-menu-column">
												<a class="dropdown-item" @click="changePage('StackingPurchase')">Buy Package</a>
												<a class="dropdown-item" @click="changePage('PackageHistory')">Purchase History</a>
											</div>
										</div>
									</div>
								</li>
								<li class="nav-item dropdown" :class="activeLink('team')">
									<a class="nav-link dropdown-toggle" href="#navbar-layout" data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-expanded="false" >
										<span class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/layout-2 -->
											<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="9" cy="7" r="4"></circle><path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path><path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path></svg>
										</span>
										<span class="nav-link-title">Team</span>
									</a>
									<div class="dropdown-menu">
										<div class="dropdown-menu-columns">
											<div class="dropdown-menu-column">
												<a class="dropdown-item" @click="changePage('DirectMember')">Direct Members</a>
												<a class="dropdown-item" @click="changePage('LevelView')">Level View</a>
											</div>
										</div>
									</div>
								</li>

								<li class="nav-item dropdown" :class="activeLink('bonus')">
									<a class="nav-link dropdown-toggle" href="#navbar-layout" data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-expanded="false" >
										<span class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/layout-2 -->
										       <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="9"></circle><path d="M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 0 0 0 4h2a2 2 0 0 1 0 4h-2a2 2 0 0 1 -1.8 -1"></path><path d="M12 6v2m0 8v2"></path></svg>
											</span>
										<span class="nav-link-title">Bonus</span>
									</a>
									<div class="dropdown-menu">
										<div class="dropdown-menu-columns">
											<div class="dropdown-menu-column">
												
												<a class="dropdown-item" @click="changePage('IncomePackage')">Package Bonus</a>
												<a class="dropdown-item" @click="changePage('IncomePackageMonthly')">Monthly Investor Bonus</a>
												<a class="dropdown-item" @click="changePage('IncomeDirect')">Direct Referal Bonus</a>
												<a class="dropdown-item" @click="changePage('IncomeLevel')">Level Bonus</a>
											</div>
										</div>
									</div>
								</li>

								<li class="nav-item dropdown" :class="activeLink('cashWallet')">
									<a class="nav-link dropdown-toggle" href="#navbar-help" data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-expanded="false" >
										<span class="nav-link-icon d-md-none d-lg-inline-block">
											<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12"></path><path d="M20 12v4h-4a2 2 0 0 1 0 -4h4"></path></svg>
										</span>
										<span class="nav-link-title">Cash Wallet</span>
									</a>
									<div class="dropdown-menu">
										<a class="dropdown-item" @click="changePage('WalletSummary')">Wallet</a>
										<a class="dropdown-item" @click="changePage('WalletTrnasferFund')">Fund Transfer</a>
										<a class="dropdown-item" @click="changePage('WalletTransferHistory')">Fund Transfer History</a>
									</div>
								</li>
							    <li class="nav-item dropdown" :class="activeLink('krsToken')">
									<a class="nav-link dropdown-toggle" href="#navbar-help" data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-expanded="false" >
										<span class="nav-link-icon d-md-none d-lg-inline-block">
											<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12"></path><path d="M20 12v4h-4a2 2 0 0 1 0 -4h4"></path></svg>
										</span>
										<span class="nav-link-title">KRS Token</span>
									</a>
									<div class="dropdown-menu">
										
										<a class="dropdown-item" @click="changePage('TokenWalletSummary')">Active Wallet</a>
										
										<a class="dropdown-item" @click="changePage('TokenWalletLockedSummary')">Stacking Wallet</a>
									
									</div>
								</li>
							</ul>
							
						</div>
					</div>
				</div>
			</div>
		</div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      stacking: false,
    };
  },
  
  created() {
    let stak = localStorage.getItem("cachecustom");
    if (stak) {
      this.stacking = true;
    }
  },
  computed: {
    ...mapGetters(["getRoute"]),
     ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
  },
  methods: {
    ...mapMutations(['SET_ROUTE']),
    changePage(page) {
      this.SET_ROUTE(page);
      // hide sidebar
      document.getElementById('html').classList.remove('left-sidebar-open');
    },
    logout() {
      localStorage.removeItem('authExp');
      localStorage.removeItem('authT');
      window.location = '/';
    },
	activeLink(name){
	  
	   if(name == 'dashbaord'){
         if(this.getRoute == 'Dashboard'){
			return 'active';
		 }else {
			return '';
		 }	

	   }
	   if(name == 'profile'){
		 if(this.getRoute == 'EditProfile' || this.getRoute == 'LoginPassword' || this.getRoute == 'transactionPassword'){
            return 'active';
		 }else {
			return '';
		 }

	   } 
	
	   if(name == 'business'){
          if(this.getRoute == 'ListMembers' || this.getRoute == 'totalBusiness'){
			return 'active';
		  }else {
			return '';
		  }
	   }
	   if(name == 'package'){
           if(this.getRoute == 'StackingPurchase' || this.getRoute == 'PackageHistory'){
				return 'active';
		   }else {
			  return '';
		   }
	   } 
	   if(name == 'team'){
           if(this.getRoute == 'DirectMember' || this.getRoute == 'LevelView'){
			 return 'active';
		   }else {
			  return ''
		   }
	   }
	   if(name == 'bonus'){
		 if(this.getRoute == 'IncomeLevel' || this.getRoute == 'IncomePackage'){
			 return 'active';
		   }else {
			  return ''
		   }

	   }
	   if(name == 'cashWallet'){
		 if(this.getRoute == 'WalletSummary' || this.getRoute == 'WalletTrnasferFund' || this.getRoute == 'WalletTransferHistory'){
			 return 'active';
		   }else {
			  return '';
		   }

	   }
	   if(name == 'krsToken'){
		 if(this.getRoute == 'TokenWalletSummary' || this.getRoute == 'TokenWalletLockedSummary'){
			 return 'active';
		   }else {
			  return '';
		   }

	   }
	},

    stacking() {},
    ...mapMutations(["SET_ROUTE"]),
    changePage(page) {
	 
      this.SET_ROUTE(page);
      // hide sidebar
    //   document.getElementById("app-side").classList.remove("is-open");
    },
  },
};
</script>
<style>
@media only screen and (min-width: 460px) and (max-width: 768px) {
	.navbar .navbar-brand {
  
     margin-left: -80px;
}
}



</style>
