<template>
  <div class="page-wrapper">
    <PageHeader title="Business" subTitle="View Today's Business" />

    <Table
      dataType="totalBusiness"
      url="business"
	  title="View Today's Business"
    />
  </div>
</template>
<script>
import Table from "@/components/Table";
import PageHeader from "@/components/PageHeader.vue";

export default {
  components: { Table, PageHeader },
};
</script>
