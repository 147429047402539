<template>
  <div class="page-wrapper">
    <PageHeader title="Package" subTitle="View Package Purchase History" />

    <Table
      dataType="packages"
      url="packages"
       title="Purchase History"
      :key="getTableCompKey"
     
    />
  </div>

  <!-- =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= -->
</template>
<script>
import Table from "@/components/Table";
import globalMixin from "@/views/globalMixin";
import PageHeader from "@/components/PageHeader.vue";

// import $ from "jquery";

export default {
  components: { PageHeader, Table },
  mixins: [globalMixin],
  data() {
    return {
      msg: { type: "Error", content: "", class: "alert-danger" },
      gettingData: false,
      form: {
        id: "",
        transactionPassword: "",
      },
      getTableCompKey: 221223,
      closePackageData: {
        referenceNumber: "",
        amount: "",
        paidROI: "",
        totalROI: "",
        balance: "",
        coinBalance: "",
        willReceiveCoin: "",
      },
    };
  },

  watch: {
    "msg.content": function(newValue) {
      if (newValue) {
        this.toggleMsgModel(this.msg.type, this.msg.content);
        this.msg.content = "";
      }
    },
  },

  methods: {
    validate() {
      return true;
    },
    isActivePackage(status) {
      if (status !== '<span style="color: red">In-Active</span>') {
        return true;
      } else {
        return false;
      }
    },
    closeModal() {
      $("#buyNowPackage").modal("toggle");
      this.form.id = "";
      this.form.transactionPassword = "";
    },

    successFunc() {
      this.gettingData = false;
    },

    async submit() {
      $("#buyNowPackage").modal("toggle");
      await this.postData(this.form, "/packages/close-usd-package");

      this.gettingData = false;
      this.$store.state.packages = [];
      this.getTableCompKey = this.getTableCompKey + 1;
      this.form.id = "";
      this.form.transactionPassword = "";
    },
    async closePackage(id) {
      // empty old data
      this.closePackageData = {
        referenceNumber: "",
        amount: "",
        paidROI: "",
        totalROI: "",
        balance: "",
        coinBalance: "",
        willReceiveCoin: "",
      };

      this.form.id = id;

      let res = await this.getData(`/packages/usd-package-details`, {
        refNo: id,
      });

      if (res.data) {
        $("#buyNowPackage").modal("toggle");
        this.closePackageData = res.data;
        this.closePackageData.amount = "$" + this.closePackageData.amount;
        this.closePackageData.totalROI = "$" + this.closePackageData.totalROI;
        this.closePackageData.paidROI = "$" + this.closePackageData.paidROI;
        this.closePackageData.balance = "$" + this.closePackageData.balance;
      } else if (res.error) {
        this.toggleMsgModel("Info", res.error, false);
      } else {
        this.toggleMsgModel(
          "Error",
          "Could not get package information. Please try again later.",
          false
        );  
      }
    },
  },
};
</script>
