<template>
  <header class="app-header">
				<div class="container-fluid">
					<div class="row gutters">
						<div class="col-xl-5 col-lg-5 col-md-5 col-sm-3 col-4">
							<a class="mini-nav-btn" href="#" id="app-side-mini-toggler">
								<i class="icon-menu5"></i>
							</a>
							<a href="#app-side" data-toggle="onoffcanvas" class="onoffcanvas-toggler" aria-expanded="true">
								<i class="icon-chevron-thin-left"></i>
							</a>
							<a class="navbar-brand" href="index.html"><img src="/images/logo.png" alt="" style="height: 50px; margin-left: 10px">
							     <strong style="color: black; padding-left: 7px; transform: translateY(5px); font-size: 18px; position: absolute; font-family: Barlow">KRS<br> TOKEN</strong>
							</a>
						</div>
						<div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-4">
							
							
						</div>
						<div class="col-xl-5 col-lg-5 col-md-5 col-sm-3 col-4">
							<ul class="header-actions">
								
								<li class="dropdown">
									<a href="#" id="userSettings" class="user-settings" data-toggle="dropdown" aria-haspopup="true">
										<img class="avatar" src="theme/img/user.png" alt="User Thumb" />
										<span class="user-name" style="max-width: 100px">{{ this.user.firstName }}</span>
										<i class="icon-chevron-small-down"></i>
									</a>
									<div class="dropdown-menu lg dropdown-menu-right" aria-labelledby="userSettings">
										<ul class="user-settings-list">
											<li>
												<a @click="changePage('EditProfile')">
													<div class="icon">
														<i class="icon-account_circle"></i>
													</div>
													<p>View Profile</p>
												</a>
											</li>
											<li>
												<a @click="changePage('LoginPassword')">
													<div class="icon red">
														 <i class="icon-lock_outline"></i>
													</div>
													<p>Login Password</p>
												</a>
											</li>
											<li>
												<a @click="changePage('TransactionPassword')">
													<div class="icon yellow">
														<i class="icon-lock_outline"></i>
													</div>
													<p>Transaction Password</p>
												</a>
											</li>
										</ul>
										<div class="logout-btn">
											<a @click="logout" class="btn btn-primary">Logout</a>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</header>
  <!-- Header ends -->
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  created() {
    // console.log(user);
  },

  methods: {
    ...mapMutations(['SET_ROUTE']),
    changePage(page) {
      this.SET_ROUTE(page);
      // hide sidebar
      document.getElementById('html').classList.remove('left-sidebar-open');
    },
    logout() {
      localStorage.removeItem('authExp');
      localStorage.removeItem('authT');
      window.location = '/';
    },
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
  },
};
</script>

<style scoped>
.headerlogo {
  width: 150px;
  /*height: 70px !important;
  transform: translateY(-5px);*/
}
</style>
