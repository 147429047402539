<template>
  <div>
    <div class="page-body">
      <div class="container-xl">
        <div class="card">
          <div class="card-header">
            <i
              @click="$store.state.currentComponent = 'LevelView'"
              v-if="this.dataType === 'levelDetails'"
              class="fa fa-arrow-left backIcon  "
              aria-hidden="true"
              style="margin-right: 20px"
            ></i>
            <!-- tile -->
            <h3
            class="card-title"
              v-if="this.dataType === 'levelDetails'"
              style="display: inline-block"
            >
              Level
              {{
                this.$store.state.tableHeaders[this.dataType].levelNo
              }}
              Details
            </h3>

            <h3 class="card-title" v-else>
              {{ $store.state.tableHeaders[this.dataType].title }}
              &nbsp;&nbsp;
              <span v-if="$store.state.tableHeaders[this.dataType].totalIncome"
                >:-&nbsp;&nbsp; <span v-if="this.dataType != 'incomePackage'">$</span>{{ $store.state.tableHeaders[this.dataType].totalIncome }}
              </span>
             
            </h3>

            <div style="margin-left: auto;">
              <i
                class="fa fa-refresh refreshIcon"
                @click="
                  initialize(
                    $store.state.tableHeaders[this.dataType].pagination
                      .currentPage
                  )
                "
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <div class="card-body border-bottom py-3">
            <div class="d-flex">
              <div class="text-muted"></div>
              <div class="ms-auto text-muted">
                <SearchItems
                  v-if="$store.state.tableHeaders[this.dataType].searchItems"
                  :dataType="dataType"
                  @clicked="doSearch"
                  @cancel="cancelSearch"
                />
              </div>
            </div>
            <div style="clear:both"></div>
            <!-- search box end -->
            <div
              v-if="
                this.dataType === 'TokenWalletSummary' ||
                  this.dataType === 'TokenWalletLockedSummary'
              "
              style="margin-bottom: 20px"
            >
              <h4 style="font-weight: 400; font-size: 14px">
                <div class="my-2 d-inline-block w-100">
                  <b> Token Address: </b>
                  <a @click="openWalletAdd">
                    {{
                      this.dataType === "TokenWalletSummary"
                        ? this.user.walletAddress
                        : this.user.lockedWalletAdd
                    }}
                    
                  </a>
                </div>
                <div class="my-2 d-inline-block w-100">
                  <b>Token Balance: </b>

                
                 
                   {{
                      this.dataType === "TokenWalletSummary"
                        ? this.user.krsTokenBalance
                        : this.user.krsLockedTokenBalance
                    }} ( ${{
                      this.dataType === "TokenWalletSummary"
                        ? (this.user.krsTokenBalance * user.coinRate).toFixed(4)
                        : (this.user.krsLockedTokenBalance * user.coinRate).toFixed(4)
                    }})
            
             
                </div>
              </h4>
            </div>
            <div style="clear:both"></div>
            <div
              v-if="this.dataType === 'totalBusiness'"
              style="margin-bottom: 60px"
            >
              <h4 style="font-weight: 500">
                <div style="float: left">
                  Total Business:
                  {{
                    this.$store.state.tableHeaders[this.dataType].totalBusiness
                  }}
                </div>
                <div style="float: right">
                  Today's Business:
                  {{
                    this.$store.state.tableHeaders[this.dataType].todaysBusiness
                  }}
                </div>
              </h4>
            </div>
          </div>
          <div class="table-responsive p-3">
            <table class="table card-table table-vcenter text-nowrap datatable">
              <thead style="background-color:#1f1f1f;">
                <tr
                  v-if="
                    !$store.state.gettingData &&
                      $store.state[this.dataType].length > 0
                  "
                >
                  <th
                    v-for="item in $store.state.tableHeaders[this.dataType]
                      .content"
                    :key="item"
                  >
                    {{ item }}
                  </th>
                </tr>
              </thead>

              <tbody
                v-if="
                  !$store.state.gettingData &&
                    $store.state[this.dataType].length > 0 &&
                    (this.dataType === 'TokenWalletSummary' ||
                      this.dataType === 'TokenWalletLockedSummary')
                "
              >
                <tr
                  v-for="(item, index) in $store.state[this.dataType]"
                  :key="index"
                >
                  <td>
                    {{
                      index +
                        $store.state.tableHeaders[this.dataType].pagination.from
                    }}
                  </td>
                  <td><span v-html="item.memberID"></span></td>
                  <td><span v-html="item.refNum"></span></td>

                  <td><span v-html="item.type"></span></td>

                  <td v-if="this.dataType === 'TokenWalletLockedSummary'">
                    <span>{{ item.lockedDuration }} Months</span>
                  </td>

                  <td>
                    <span
                      id="hashcode"
                      @click="viewHashDetails(item.hash)"
                      v-html="item.hash"
                      style="cursor: pointer"
                    ></span>
                  </td>
                  <td><span v-html="item.description"></span></td>
                  <td><span v-html="item.amount"></span></td>
                  <td v-if="this.dataType === 'TokenWalletSummary'">
                    <span v-html="item.incomeDate"></span>
                  </td>
                  <td v-if="this.dataType === 'TokenWalletLockedSummary'">
                    <span v-html="item.unlockOn"></span>
                  </td>
                  <td v-if="$store.state.tableHeaders[this.dataType].action">
                    <slot v-bind:data="item"></slot>
                  </td>
                </tr>
              </tbody>

              <tbody
                v-else-if="
                  !$store.state.gettingData &&
                    $store.state[this.dataType].length > 0 &&
                    this.dataType === 'wallet2coinHistory'
                "
              >
                <tr
                  v-for="(item, index) in $store.state[this.dataType]"
                  :key="index"
                >
                  <td>
                    {{
                      index +
                        $store.state.tableHeaders[this.dataType].pagination.from
                    }}
                  </td>
                  <td><span v-html="item.requestedOn"></span></td>
                  <td><span v-html="item.referenceNumber"></span></td>
                  <td>
                    <span
                      id="hashcode"
                      @click="viewHashDetails(item.transactionDetails)"
                      v-html="item.transactionDetails"
                      style="cursor: pointer"
                    ></span>
                  </td>

                  <td><span v-html="item.approveOrRejectedOn"></span></td>
                  <td><span v-html="item.status"></span></td>
                  <td><span v-html="item.amount"></span></td>

                  <td v-if="$store.state.tableHeaders[this.dataType].action">
                    <slot v-bind:data="item"></slot>
                  </td>
                </tr>
              </tbody>

              <tbody
                v-else-if="
                  !$store.state.gettingData &&
                    $store.state[this.dataType].length > 0 &&
                    this.dataType === 'walletSummary'
                "
              >
                <tr
                  v-for="(item, index) in $store.state[this.dataType]"
                  :key="index"
                >
                  <td>
                    {{
                      index +
                        $store.state.tableHeaders[this.dataType].pagination.from
                    }}
                  </td>
                  <td>{{ item.createdOn }}</td>
                  <td>{{ changeIncomeToBonus(item.referenceType) }}</td>
                  <td>{{ changeIncomeToBonusComments(item.comments) }}</td>
                  <td>{{ item.amount }}</td>
                </tr>
              </tbody>

              <tbody
                v-else-if="
                  !$store.state.gettingData &&
                    $store.state[this.dataType].length > 0 &&
                    this.dataType === 'packages'
                "
              >
                <tr
                  v-for="(item, index) in $store.state[this.dataType]"
                  :key="index"
                >
                  <td>
                    {{
                      index +
                        $store.state.tableHeaders[this.dataType].pagination.from
                    }}
                  </td>
                  <td>{{ item.referenceNumber }}</td>
                  <td>{{ item.amount }}</td>
                  <td>{{ item.createdOn }}</td>
                  <td><span v-html="item.status"></span></td>

                  <!-- <td v-if="$store.state.tableHeaders[this.dataType].action">
                      <slot v-bind:data="item"></slot>
                    </td> -->
                </tr>
              </tbody>

              <tbody
                v-else-if="
                  !$store.state.gettingData &&
                    $store.state[this.dataType].length > 0
                "
              >
                <tr
                  v-for="(item, index) in $store.state[this.dataType]"
                  :key="index"
                >
                  <td>
                    {{
                      index +
                        $store.state.tableHeaders[this.dataType].pagination.from
                    }}
                  </td>
                  <td v-for="(i1, index) in item" :key="index">
                    <span v-html="i1"></span>
                  </td>
                  <td v-if="$store.state.tableHeaders[this.dataType].action">
                    <slot v-bind:data="item"></slot>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td
                    style="text-align: center; height: 200px; line-height: 200px; font-size: 20px;font-weight: 500"
                  >
                    <span v-if="searchData.value">
                      {{ $store.state.tableHeaders[this.dataType].noDataMsg }}
                      with given search value
                    </span>
                    <span v-else>
                      {{ $store.state.tableHeaders[this.dataType].noDataMsg }}
                    </span>
                  </td>
                </tr>
              </tbody>
              <!-- <tbody>
          <tr>
            <td>
              <input
                class="form-check-input m-0 align-middle"
                type="checkbox"
                aria-label="Select invoice"
              />
            </td>
            <td><span class="text-muted">001401</span></td>
            <td>
              <a href="invoice.html" class="text-reset" tabindex="-1"
                >Design Works</a
              >
            </td>
            <td><span class="flag flag-country-us"></span>Carlson Limited</td>
            <td>87956621</td>
            <td>15 Dec 2017</td>
            <td><span class="badge bg-success me-1"></span> Paid</td>
            <td>$887</td>
            <td class="text-end">
              <span class="dropdown">
                <button
                  class="btn btn-yellow dropdown-toggle align-text-top"
                  data-bs-boundary="viewport"
                  data-bs-toggle="dropdown"
                >
                  Actions
                </button>
                <div class="dropdown-menu dropdown-menu-end">
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                </div>
              </span>
            </td>
          </tr>
        </tbody> -->
            </table>
          </div>

          <div class="card-footer d-flex align-items-center">
            <!-- <p class="m-0 text-muted">
        Showing <span>1</span> to <span>8</span> of <span>16</span> entries
      </p> -->

            <!-- pagination start -->
            <div
              class="row w-100"
              v-if="
                $store.state.tableHeaders[this.dataType].pagination !==
                  'none' &&
                  !$store.state.gettingData &&
                  $store.state[this.dataType].length > 0
              "
            >
              <div class="col-12 col-md-7 text-center text-md-start my-2">
                Page &nbsp;&nbsp;&nbsp;

                <select
                  name="goto"
                  v-model="
                    this.$store.state.tableHeaders[this.dataType].selectedGoto
                  "
                  id="goto"
                  v-on:change="
                    initialize(
                      this.$store.state.tableHeaders[this.dataType].selectedGoto
                    )
                  "
                  style="padding: 5px"
                >
                  <option
                    v-for="(item, index) in this.$store.state.tableHeaders[
                      this.dataType
                    ].goToItems"
                    :key="index"
                    :value="item"
                    >{{ item }}</option
                  >
                </select>
                &nbsp;&nbsp;&nbsp;
                {{ $store.state.tableHeaders[this.dataType].pagination.from }}-
                {{ $store.state.tableHeaders[this.dataType].pagination.to }}
                &nbsp;of&nbsp;
                {{ $store.state.tableHeaders[this.dataType].pagination.total }}

                &nbsp;&nbsp; &nbsp;&nbsp; Per page &nbsp;&nbsp;&nbsp;
                <select
                  name="perPage"
                  v-model="
                    this.$store.state.tableHeaders[this.dataType].perPage
                  "
                  id="perPage"
                  style="padding: 5px"
                  v-on:change="initialize()"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                  <option value="2000">2000</option>
                </select>
              </div>
              <div class="col-12 col-md-5 text-center text-md-end my-2">
                <i
                  @click="initialize(1)"
                  style="font-size: 20px"
                  :class="
                    $store.state.tableHeaders[this.dataType].pagination
                      .currentPage === 1
                      ? 'clickicondisabled'
                      : ''
                  "
                  class="fa fa-angle-double-left clickicon"
                  aria-hidden="true"
                ></i
                >&nbsp;&nbsp;
                <i
                  @click="
                    initialize(
                      $store.state.tableHeaders[this.dataType].pagination
                        .prevPage
                    )
                  "
                  style="font-size: 20px"
                  :class="
                    !$store.state.tableHeaders[this.dataType].pagination
                      .prevPage
                      ? 'clickicondisabled'
                      : ''
                  "
                  class="fa fa-angle-left clickicon"
                  aria-hidden="true"
                ></i
                >&nbsp;&nbsp;
                <i
                  @click="
                    initialize(
                      $store.state.tableHeaders[this.dataType].pagination
                        .nextPage
                    )
                  "
                  style="font-size: 20px;"
                  :class="
                    !$store.state.tableHeaders[this.dataType].pagination
                      .nextPage
                      ? 'clickicondisabled'
                      : ''
                  "
                  class="fa fa-angle-right clickicon"
                  aria-hidden="true"
                ></i
                >&nbsp;&nbsp;
                <i
                  @click="
                    initialize(
                      $store.state.tableHeaders[this.dataType].pagination
                        .lastPage
                    )
                  "
                  style="font-size: 20px"
                  :class="
                    $store.state.tableHeaders[this.dataType].pagination
                      .lastPage ===
                    $store.state.tableHeaders[this.dataType].pagination
                      .currentPage
                      ? 'clickicondisabled'
                      : ''
                  "
                  class="fa fa-angle-double-right clickicon"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
            <!-- pagination ends -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";
import globalMixin from "@/views/globalMixin";

import SearchItems from "../components/Search";

export default {
  mixins: [globalMixin],
  components: { SearchItems },
  props: ["dataType", "subDataType", "url", "title"],
  data() {
    return {
      searchData: {},
      counter: true,
    };
  },
  created() {
    if (this.$store.state[this.dataType].length < 1) {
      this.initialize(1);
    }
    // console.log(this.$store.state[this.dataType]);
  },

  methods: {
    openWalletAdd() {
      let address =
        this.dataType === "TokenWalletSummary"
          ? this.user.walletAddress
          : this.user.lockedWalletAdd;
      window.open(
        `${this.$store.state.gdccScanURL}/address/${address}`,
        `_gdc_${this.user.userName}`
      );
    },
    openAddPopup() {
      $("#createAffiliateMember").modal("toggle");
    },
    changeIncomeToBonus(type) {
      if (type === "Binary Income") {
        return "Binary Bonus";
      } else if (type === "ROI Income") {
        return "Return Bonus";
      } else if (type === "Direct Income") {
        return "Direct Bonus";
      } else if (type === "Unilevel Income") {
        return "Unilevel Bonus";
      } else {
        return type;
      }
    },
    changeIncomeToBonusComments(comment) {
      if (comment.includes("Binary income")) {
        return comment.replace("Binary income", "Binary bonus");
      } else if (comment.includes("ROI Income")) {
        return comment.replace("ROI Income", "Return bonus");
      } else if (comment.includes("Direct Income")) {
        return comment.replace("Direct Income", "Direct bonus");
      } else if (comment.includes("Unilevel Income")) {
        return comment.replace("Unilevel Income", "Unilevel bonus");
      } else {
        return comment;
      }
    },
    viewHashDetails(hash) {
      window.open(`${this.$store.state.gdccScanURL}/tx/${hash}`);
    },
    cancelSearch() {
      this.searchData = {};
      this.initialize();
    },
    doSearch(search) {
      this.searchData = search;
      this.initialize();
    },
    ...mapMutations(["SET_ROUTE"]),
    alert(color, text) {
      console.log("alert");
      // this.snackbar = { enable: true, text: text, color: color };
    },
    initialize(pageNo) {
      if (
        this.$store.state.gettingData === false &&
        this.isLoggedInAsDifferentAcc()
      ) {
        this.selectedGoto = pageNo;
        this.$store.state.gettingData = true;
        this.$store.state[this.dataType] = [];

        this.$store.state.tableHeaders[this.dataType].pagination = "none";
        let url = `${this.$store.state.baseURL}/${this.url}`;

        // var searchData = { type: this.searchType, value: this.searchValue };
        if (pageNo) {
          url = `${url}?page=${pageNo}&limit=${
            this.$store.state.tableHeaders[this.dataType].perPage
          }`;
          this.pageNo = pageNo;
        } else {
          url = `${url}?page=1&limit=${
            this.$store.state.tableHeaders[this.dataType].perPage
          }`;
        }

        // add level if detail view page
        if (this.dataType === "levelDetails") {
          let level = this.$store.state.tableHeaders[this.dataType].levelNo;
          url = `${url}&level=${level}`;
        }

        // // Add left or right if binary view page is
        if (this.dataType === "binaryViewLeft") {
          url = `${url}&left=true`;
        } else if (this.dataType === "binaryViewRight") {
          url = `${url}&right=true`;
        }

        if (this.searchData.type && this.searchData.value) {
          url = `${url}&search=${this.searchData.type},${this.searchData.value}`;
        }

        let self = this;

        axios(url)
          .then((response) => {
            if (response.status == 200) {
              this.$store.state[this.dataType] = response.data.data;
              this.$store.state.gettingData = false;
              this.$store.state.tableHeaders[this.dataType].pagination =
                response.data.pagination;
              this.$store.state.tableHeaders[this.dataType].goToItems = [];
              for (
                let i = 1;
                i <=
                this.$store.state.tableHeaders[this.dataType].pagination
                  .lastPage;
                i++
              ) {
                this.$store.state.tableHeaders[this.dataType].goToItems.push(i);
              }
              this.$store.state.tableHeaders[this.dataType].selectedGoto =
                response.data.pagination.currentPage;

              // Add total income if income page
              if (
                this.dataType === "incomeRoi" ||
                this.dataType === "incomeDirect" ||
                this.dataType === "incomeBinary" ||
                this.dataType === "incomeLevel" ||
                this.dataType === "walletSummary" ||
                this.dataType === "CPBWallet" ||
                this.dataType === "incomePromotional" ||
                this.dataType === "AffiliateLevelBonus" ||
                this.dataType === "affiliateList" ||
                this.dataType === "ListMembers" ||
                this.dataType === "incomePackage"
              ) {
                this.$store.state.tableHeaders[this.dataType].totalIncome =
                  response.data.totalIncome;
              }
              // Add coin details on coin received table
              if (this.dataType === "TokenWalletSummary") {
                this.$store.state.tableHeaders[this.dataType].totalReceived =
                  response.data.totalReceived;
                this.$store.state.tableHeaders[this.dataType].totalTransfered =
                  response.data.totalTransfered;
                this.$store.state.tableHeaders[this.dataType].balance =
                  response.data.balance;
                this.$store.state.tableHeaders[this.dataType].totalLocked =
                  response.data.totalLocked;
                this.$store.state.tableHeaders[
                  this.dataType
                ].totalTransferrable = response.data.totalTransferrable;
              }
              if (this.dataType === "totalBusiness") {
                this.$store.state.tableHeaders[this.dataType].todaysBusiness =
                  response.data.todaysBusiness;
                this.$store.state.tableHeaders[this.dataType].totalBusiness =
                  response.data.totalBusiness;
              }
            } else {
              this.alert("black", "Oops! Something went wrong");
            }
          })
          .catch((error) => {
            if (error) {
              // const { response } = error;
              // const { request, ...errorObject } = response;
              if (error.response) {
                if (error.response.status == 419) {
                  // Missing csrf
                  console.log(error);
                  // window.location = '/login';
                } else if (error.response.status == 401) {
                  // Not logged-in Unautorized
                  console.log(error.response.status);
                  self.SET_ROUTE("Login");

                  // window.location = '/login';
                } else if (error.response.status == 500) {
                  // run this to fix binary loading issues

                  if (
                    self.counter === true &&
                    (self.dataType === "binaryViewLeft" ||
                      self.dataType === "binaryViewRight")
                  ) {
                    self.$store.state.gettingData = false;
                    self.counter = false;
                    self.initialize(1);
                  }

                  // window.location = '/login';
                } else {
                  self.alert("black", "Oops! Something went wrong. Try Again");
                  self.$store.state.gettingData = false;
                }
              } else {
                // Not connected to internet
                console.log(error);
                // window.location = '/login';
              }
            }
          });
      }
    },
  },
};
</script>

<style>
#loading-wrapper #loader {
  position: absolute;
  top: 40%;
  left: 42%;
  margin-left: -200px;
  margin-top: -60px;
}
.loaded #loader {
  opacity: 0;
  -webkit-transition: all 1s ease-out;
  transition: all 1s ease-out;
}
.backIcon {
  cursor: pointer;
  font-size: 17px;
  transform: translateY(-1px);
}
.clickicon {
  cursor: pointer;
  transform: translateY(2px);
}
.clickicondisabled {
  cursor: none;
  color: lightgrey;
}
.refreshIcon {
  color: white;
  float: right;
  font-size: 20px;
  margin: 7px 7px 7px 0;
  cursor: pointer;
}
.closeIcon {
  transform: translate(-24px, 1px);
  cursor: pointer;
  font-size: 16px;
}
.searchInputbox {
  margin-left: 10px;
  display: inline-block;
  padding-right: 30px;
  width: 150px;
}

#hashcode:hover {
  color: blue;
  border-bottom: 1px solid blue;
}

table {
  margin-bottom: 55px !important;
}
</style>
