<template>
  <div class="page-wrapper">
    <PageHeader title="Team" subTitle="View Direct Members" />

    <Table
      dataType="directMembers"
      url="network/direct-member"
      title="View Direct Members"
    />
  </div>
</template>
<script>
import Table from "@/components/Table";
import PageHeader from "@/components/PageHeader.vue";

export default {
  components: { Table, PageHeader },
};
</script>
