<template>
  <div class="page-wrapper">
    <div id="overlayer" v-if="gettingData"></div>
    <div id="overlay3" v-if="gettingData" class="loader3">
      <div id="loading-wrapper">
        <div id="loader">
          <img
            src="/images/loader1.gif"
            class="img-responsive"
            style="height: 200px;width: 450px"
          />
        </div>
      </div>
    </div>
    <PageHeader title="Wallet" subTitle="Transfer Fund" />

    <div class="page-body">
      <div class="container-xl">
        <div class="card">
          <div class="card-body">

              <div class="row gutter" v-if="user.canTransferFund === 'No'">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="panel  b-primary bt-sm ">
                  <div class="panel-content">
                    <div
                      class="widget-list list-sm list-left-element widgetCls"
                    >
                      <ul style="margin:0;padding:30px;list-style:none;text-align:center;">
                        <!--  alert -->
                        <li>
                          <div
                            class="alert alert-info"
                            style="font-size:30px;color:white;font-weight:bold;display:flex;align-items:center;justify-content:center;height:250px;max-width:800px;margin:0 auto;text-align:center;"                          >
                            Fund transfer is under maintenance at this
                            moment. Please try again later.
                          </div>
                        </li>
                        <!-- alert end -->
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          
            <div v-else>

               Your current wallet balance is :
              <b> ${{ this.user.totalWalletBalance }} </b>

              <div class="mb-3">
                <div
                    v-if="msg.content"
                    :class="msg.class"
                    class="alert alert-dismissible"
                    style="font-size: 15px; display: block; background: red; color: white"
                  >
                    <a @click="msg.content = ''"
                      style="color: white; font-size: 17px"
                      href="#"
                      class="close"
                      data-dismiss="alert"
                      aria-label="close"
                      >&times;</a
                    >
                    <strong style="padding-left: 10px; ">{{ msg.type }}</strong> {{ msg.content }}.
                  </div>
               
              </div>

              <div class="mb-3">
                <label class="form-label">Amount</label>
                <InputField
                  fieldName="amount"
                  :error="errors.amount"
                  placeholder="Amount"
                  v-model:data="form.amount"
                  errorClass="errorRed"
                />
              </div>
              <div class="mb-3">
                <label class="form-label"
                  >Receiver's member ID or Username</label
                >
                <InputField
                  fieldName="receiverUserName"
                  :error="errors.receiverUserName"
                  placeholder="Receiver User Name"
                  v-model:data="form.receiverUserName"
                  @validateReceiverName="isUserNameValid"
                  errorClass="errorRed"
                />
                <div
                  v-if="recieversFullName"
                  style="color: green; font-weight: bold; margin-bottom: 12px;"
                >
                  Receiver's FullName :- {{ recieversFullName }}
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label">Transaction Password</label>
                <InputField
                  fieldName="transactionPassword"
                  :error="errors.transactionPassword"
                  placeholder="Transaction Password"
                  v-model:data="form.transactionPassword"
                  errorClass="errorRed"
                />
              </div>
              <a
                  @click="postData(this.form, '/wallet/transfer')"
                  class="btn btn-primary"
                  >Submit</a
                >
            </div>
          </div>
         
        </div>
      </div>
    </div>
  </div>

  <!-- CONTENT -->
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import InputField from "@/components/Input";
import axios from "axios";
import globalMixin from "@/views/globalMixin";
import PageHeader from "@/components/PageHeader.vue";

export default {
  mixins: [globalMixin],
  components: { InputField, PageHeader },
  data() {
    return {
      recieversFullName: null,
      gettingData: false,

      form: { amount: null, receiverUserName: null, transactionPassword: null },
      errors: {
        amount: null,
        receiverUserName: null,
        transactionPassword: null,
      },
      msg: { type: "Error", content: '', class: "alert-danger" },
    };
  },
  watch: {
    "form.amount": function(newValue, oldValue) {
      this.validateFields("Amount", "amount", newValue, /^\d+$/);
    },
    "form.receiverUserName": function(newValue, oldValue) {
      this.validateFields(`Receiver's username`, "receiverUserName", newValue);
    },
    "form.transactionPassword": function(newValue, oldValue) {
      this.validateFields(
        `Transaction password`,
        "transactionPassword",
        newValue
      );
    },
  },

  methods: {
    async isUserNameValid() {
      if (this.form.receiverUserName) {
        const result = await axios.post(
          `${this.$store.state.baseURL}/auth/validate`,
          {
            fundRecieverID: this.form.receiverUserName,
          }
        );

        if (result.data.status === "success") {
          this.recieversFullName = result.data.data;
        } else {
          this.errors.receiverUserName = `Reciever's Username or ID is invalid`;
          this.recieversFullName = null;
        }
      }
    },
  },
};
</script>

<style>
.widgetCls li {
  background: unset !important;
}
</style>
