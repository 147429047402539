<template>
  <div id="successModel" class="modal fade" role="dialog">
    <div class="modal-dialog" style="margin-top: 350px;">
      <!-- Modal content-->
      <div class="modal-content">
        <div
          class="sweet-alert showSweetAlert visible"
          data-custom-class=""
          data-has-cancel-button="false"
          data-has-confirm-button="true"
          data-allow-outside-click="false"
          data-has-done-function="false"
          data-animation="pop"
          data-timer="null"
          style="display: block; margin-top: -168px;"
        >
          <div class="sa-icon sa-info" style="display: none;"></div>
          <div
            v-if="$store.state.MsgModel.type === 'Success'"
            class="sa-icon sa-success animate"
            style="display: block;"
          >
            <span class="sa-line sa-tip animateSuccessTip"></span>
            <span class="sa-line sa-long animateSuccessLong"></span>

            <div class="sa-placeholder"></div>
            <div class="sa-fix"></div>
          </div>

          <!-- info model box -->

          <div
            v-if="$store.state.MsgModel.type === 'Info'"
            class="sa-icon sa-warning pulseWarning"
            style="display: block;"
          >
            <span class="sa-body pulseWarningIns"></span>
            <span class="sa-dot pulseWarningIns"></span>
          </div>
          <div v-if="$store.state.MsgModel.type === 'Error'">
            <i class="fa fa-exclamation-triangle" aria-hidden="true" style="color: red; font-size: 80px"></i>
          </div>

          <div class="sa-icon sa-custom" style="display: none;"></div>
          <!-- <h2>Good job!</h2> -->
          <p style="display: block;margin-top: 25px">{{ $store.state.MsgModel.msg }}</p>
          <fieldset>
            <input type="text" tabindex="3" placeholder="" />
            <div class="sa-input-error"></div>
          </fieldset>
          <div class="sa-button-container">
            <button class="cancel" tabindex="2" style="display: none; box-shadow: none;">Cancel</button>
            <div class="sa-confirm-button-container">
              <button
                @click="toggleSuccessModel"
                class="confirm"
                tabindex="1"
                style="display: inline-block; background-color: rgb(140, 212, 245); box-shadow: rgba(140, 212, 245, 0.8) 0px 0px 2px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px inset;"
              >
                OK
              </button>
              <div class="la-ball-fall">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    toggleSuccessModel() {
      $('#successModel').modal('toggle');
      if (this.$store.state.MsgModel.logout) {
        localStorage.removeItem('authT');
        localStorage.removeItem('authExp');
        window.location = '/';
      }
    },
  },
};
</script>
